<template>
	<button :class="type" type="button" rel="noopener noreferrer" @click="openBookingEngine({ ...$props })">
		<slot />
	</button>
</template>

<script setup>
defineProps({
	type: { type: String, default: 'default' },
	roomtypeid: { type: Number, default: 0 },
	rateid: { type: String, default: '' },
});

const openBookingEngine = ({ roomtypeid: roomTypeIds, rateid: rateIds }) => {
	window.clockPmsWbeShow && window.clockPmsWbeShow({ roomTypeIds: [roomTypeIds], rateIds: [rateIds] });
};
</script>

<style lang="scss" scoped>
button {
	color: #fff;
	cursor: pointer;

	&.fixedmobile {
		display: none;
		width: 100%;
		position: fixed;
		bottom: 0;
		margin: 0;
		padding: 20px 0;
		z-index: 100;

		svg {
			margin-right: 5px;
		}
	}

	&:hover {
		text-decoration: none;
	}
}

@media (max-width: 600px) {
	button.fixedmobile {
		display: flex;
		flex-flow: row nowrap;
		justify-content: center;
		align-items: center;

		svg,
		:deep(svg) {
			margin: -1px 6px 1px 0;
		}
	}
}
</style>
